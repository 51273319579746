import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VVS_PTW_DATA_SHARE {
  private VVS_VOIS_SITE_ID_SUBJECT = new BehaviorSubject<string>('');
  vvs_vois_siteId$: Observable<string> = this.VVS_VOIS_SITE_ID_SUBJECT.asObservable();

  VVS_VOIS_SET_SITE_ID(vvs_vois_siteId: string) {
    if(vvs_vois_siteId != this.VVS_VOIS_SITE_ID_SUBJECT.value) this.VVS_VOIS_SITE_ID_SUBJECT.next(vvs_vois_siteId);
  }

  VVS_VOIS_GET_SITE_ID(): any {
    return this.VVS_VOIS_SITE_ID_SUBJECT.value;
  }
}
