import { Routes } from '@angular/router';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { AuthGuardFn } from './_core/guard/auth.guard';

export const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [AuthGuardFn],
        children: [
          {path: '', redirectTo: '/signin', pathMatch: 'full'},
          {
            path: 'admin',
            loadChildren: () => import('./admin-panel/admin-panel.module').then( (m) => m.AdminPanelModule)
          },
          {
            path: 'ptw',
            loadChildren: () => import('./pages/ptw/ptw.module').then( (m) => m.PtwModule)
          },
          {
            path: 'hira',
            loadChildren: () => import('./pages/hira/hira.module').then( (m) => m.HiraModule)
          },
          {
            path: 'action-tracker',
            loadChildren: () => import('./pages/action-tracker/action-tracker.module').then( (m) => m.ActionTrackerModule)
          },
          {
            path: 'inspection',
            loadChildren: () => import('./pages/inspection/inspection.module').then( (m) => m.InspectionModule)
          },
          {
            path: 'training',
            loadChildren: () => import('./pages/training/training.module').then( (m) => m.TrainingModule)
          },
          {
            path: 'master',
            loadChildren: () => import('./pages/master/master.module').then( (m) => m.MasterModule)
          },
          {
            path: 'reports',
            loadChildren: () => import('./pages/reports/reports.module').then( (m) => m.ReportsModule)
          },
        ]
      },
      {
        path: '',
        component: AuthLayoutComponent,
        loadChildren: () => import('./authentication/authentication.module').then( (m) => m.AuthenticationModule)
      }
];
