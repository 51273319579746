<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed': ''">
    <div class="logo-container">
      <!-- <button class="logo" (click)="toggleCollapse()">
        <span class="material-symbols-outlined">
          grid_view
        </span></button>  -->
      <!-- <div class="logo-text" @fadeInOut *ngIf="collapsed">_<span class="lp-n">VO<b>IS</b></span> | SHW</div> -->
      <div class="logo-text" @fadeInOut *ngIf="collapsed"><img src="assets/images/VOIS_LOGO_white.png" alt="VOIS | SHW"> | SHW</div>
      <!-- <button class="btn-close" @rotate *ngIf="collapsed" (click)="closeSidenav()">
        <i class="fal fa-times close-icon"></i>
      </button> -->
    </div>

    <div class="scrollwrap scrollwrap_delayed">
      <ul class="sidenav-nav">
        <li class="sidenav-nav-item" *ngFor="let data of navData">
          <a class="sidenav-nav-link" (click)="handleClick(data)"
            *ngIf="data.items && data.items.length > 0"
            [ngClass]="getActiveClass(data)"
          >
            <!-- <i class="sidenav-link-icon" [class]="data.icon"></i> -->
            <span class="sidenav-link-text" *ngIf="collapsed">
              {{data.label}}
            </span>
            <i *ngIf="data.items && collapsed" class="menu-collapse-icon"
                [ngClass]="!data.expanded ? 'fal fa-angle-right' : 'fal fa-angle-down'"
            ></i>
          </a>

          <a class="sidenav-nav-link"
            *ngIf="!data.items || (data.items && data.items.length === 0)"
            [routerLink]="[data.routerLink]"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            >
            <!-- <i class="sidenav-link-icon" [class]="data.icon"></i> -->
            <span class="sidenav-link-text" *ngIf="collapsed">
              {{data.label}}
            </span>
          </a>
          <div *ngIf="collapsed && data.items && data.items.length > 0">
            <app-sublevel-menu
            [data] = "data"
              [collapsed]="collapsed"
              [multiple]="multiple"
              [expanded]="data.expanded">
            </app-sublevel-menu>
          </div>
        </li>
      </ul>
    </div>

    <div class="sidenav-footer">

      <a href="javascript:void(0)" [matMenuTriggerFor]="menu">Privacy Policy</a>
      <mat-menu #menu="matMenu" class="mat-menu-custom-footer">
        <button mat-menu-item (click)="openLink('https://vodafone.sharepoint.com/sites/PrivacyMatters/SitePages/Employee-Privacy-Statement.aspx?csf=1&e=PahNAV')"><span class="material-symbols-outlined">privacy_tip</span> Employee privacy</button>
        <button mat-menu-item (click)="openLink('https://vodafone.sharepoint.com/sites/PrivacyMatters/SitePages/External-Workforce.aspx?csf=1&e=hMss8f')"><span class="material-symbols-outlined">privacy_tip</span> External workforce privacy</button>
        <button mat-menu-item (click)="openLink('https://personaldataprotection.bureauveritas.com/#/privacypolicy')"><span class="material-symbols-outlined">privacy_tip</span> BSI Privacy</button>
      </mat-menu>
    </div>

  </div>
