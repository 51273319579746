import { Injectable, inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router,) { }

  canActivate() {
    if (typeof (this.authService.VVS_VOIS_CURRENT_USER_VALUE) != 'undefined' && this.authService.VVS_VOIS_CURRENT_USER_VALUE != null && this.authService.VVS_VOIS_CURRENT_USER_VALUE) {
      return true;
    }
    this.router.navigate(['/signin']);
    return false;
  }
}

export const AuthGuardFn: CanActivateFn = (route, state) => {
  return inject(AuthGuard).canActivate();
}
