import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { VVS_VOIS_GET_ACL } from "../_common/constant";
import { VVS_VOIS_ACL } from "../interfaces/acl";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class ACLService {

  constructor(private http: HttpClient) { }

  VVS_VOIS_GET_ACL(param : any): Observable<VVS_VOIS_ACL> {
    return this.http.post<VVS_VOIS_ACL>(VVS_VOIS_GET_ACL, param);
  }

}
