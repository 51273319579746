import { trigger, transition, animate, keyframes, style } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInOut, INavbarData } from './helper'
import { ACLService } from '../../_core/service/acl.service';
import { VVS_VOIS_ACL } from '../../_core/interfaces/acl';
import { VVS_PTW_DATA_SHARE } from '../../_core/service/data_share.service';
import { Subscription } from 'rxjs';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-sidebar',
  standalone: false,

  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  animations: [
    fadeInOut,
    trigger('rotate', [
      transition(':enter', [
        animate('1000ms',
          keyframes([
            style({ transform: 'rotate(0deg)', offset: '0' }),
            style({ transform: 'rotate(2turn)', offset: '1' })
          ])
        )
      ])
    ])
  ]
})
export class SidebarComponent implements OnChanges, OnInit, OnDestroy {

  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed = true;
  @Input() collapsedInput!: boolean;
  screenWidth = 0;
  navData: INavbarData[] = [];
  multiple: boolean = false;
  subscription!: Subscription;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 768) {
      this.collapsed = true;
      this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
    }
  }

  constructor(
    public router: Router,
    private aclService: ACLService,
    private _Datashare: VVS_PTW_DATA_SHARE,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {


    if (changes['collapsedInput']) {
      this.collapsed = this.collapsedInput
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.collapsed = true;
    this.subscription = this._Datashare.vvs_vois_siteId$.subscribe((res: string) => {
      if (res) this.VVS_VOIS_GET_ACL(res);
    })

  }

  VVS_VOIS_GET_ACL(siteId: string) {
    let params = { SITE_ID: siteId }
    this.aclService.VVS_VOIS_GET_ACL(params).subscribe((res: VVS_VOIS_ACL) => {
      console.log(res)
      this.navData = res.Response.USER_MENU_LIST;
    })
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  }

  closeSidenav(): void {
    this.collapsed = false;
    this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  }

  handleClick(item: INavbarData): void {
    if (!this.multiple) {
      for (let modelItem of this.navData) {
        if (item !== modelItem && modelItem.expanded) {
          modelItem.expanded = false;
        }
      }
    }
    item.expanded = !item.expanded
  }

  getActiveClass(data: INavbarData): string {
    return this.router.url.includes(data.routerLink) ? 'active' : '';
  }

  openLink(link: string) {
    window.open(link, '_blank');
  }
}
